<template>
  <div class="container wrap">
    <div class="row">
      <div class="col ml-2 mr-2">
        <Header :title="title"></Header>
      </div>
    </div>
    <div class="row">
      <div class="col main-buffer d-none d-sm-block">&nbsp;</div>
      <div class="col mt-4 main">
        <router-view></router-view>
      </div>
    </div>
    <div class="row">
      <div class="col ml-2 mr-2">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import metaHelpers from '@/scripts/helpers/metaHelpers.js'

export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      title: null
    }
  },
  mounted: function () {
    var vals;
    metaHelpers.setupPage(this);
    vals = metaHelpers.getMetaValues();
    this.title = vals.title;
  }
};
</script>
