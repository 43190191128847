<template>
  <div class="col">
    <PageIntro>
      <p>
        If you're
        <a href="/">sending letters and parcels</a> through the post - especially if you're sending high volumes - it pays to find ways of reducing the overall cost. Here are a few tips for saving on postage costs when sending letters and parcels...
      </p>
    </PageIntro>

    <h2>Fold your letters</h2>
    <p>
      The price of
      <a href="/">posting a letter in the UK</a> is largely based on the size of the item, not the weight. If you have an A4 envelope (large letter) it will cost you more than sending an A5 or DL envelope (letter). If you can, fold your letter to fit into a smaller envelope.
    </p>
    <h2>Reduce your packaging</h2>
    <p>
      The
      <a href="/smallparcel">price of posting a small parcel</a> is less than the
      <a href="/largeparcel">price of posting a large parcel</a> for the same weight - if you can reduce the amount of packaging you might find you can reduce the cost.
    </p>
    <h2>Send it by email</h2>
    <p>This probably feels obvious, but many individuals and businesses still send messages by post when they could be sending by email - at zero cost.</p>
    <h2>Get a franking machine</h2>
    <p>If you're sending high volumes of letters on a regular basis, it could work out cheaper to pay for a franking machine, rather than buying stamps.</p>
    <h2>Cull your mailing list</h2>
    <p>Sending letters and promotional content through the post is expensive - sending it to people that don't live there any more or who will never buy from you is a total waste of money. Look for ways to keep your mailing list up-to-date and remove anyone that is a cold lead.</p>
    <h2>Group items for the same address</h2>
    <p>If you're sending more than one item to the same person, group them together into one package to save on postage.</p>
  </div>
</template>

<script>
import PageIntro from "@/components/PageIntro";

export default {
  name: "TipsForSavingPostagePage",
  components: { PageIntro },
  meta: {
    title: "Tips For Saving On Postage Costs",
    description:
      "Tips for saving on postage costs when sending letters and parcels for business or personal"
  }
};
</script>