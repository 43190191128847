<template>
  <div class="container">
    <PageIntro>
      <p v-if="packageIntro">{{ packageIntro }}</p>
      <p>
        Want to know how many stamps you need to post your
        {{ packageName }} through Royal Mail within the UK? This
        <a href="/">How Many Stamps</a> page has the answer!
      </p>
      <p>
        The table below tells you how many stamps you need to post a
        {{ packageName }}...
      </p>
    </PageIntro>
    <PackageList
      :items="items"
      :selected="selectedKey"
      :selectedClass="selectedClass"
      :onlyPrimary="true"
    ></PackageList>
  </div>
</template>

<script>
import PackageList from "../components/PackageList";
import packages from "../data/packages.json";
import packageHelpers from "../scripts/packageHelpers.js";
import stringHelpers from "@/scripts/helpers/stringHelpers.js";
import PageIntro from "@/components/PageIntro";

export default {
  name: "PackagePage",
  components: { PackageList, PageIntro },
  data: function () {
    return {
      items: [],
      selected: null,
      selectedKey: null,
      selectedClass: null,
    };
  },
  created: function () {
    this.items = packages;
    this.selectedKey = this.$route.params.package.toLowerCase();
    this.selectedClass = this.$route.params.class;
  },
  computed: {
    packageName: function () {
      var result = packageHelpers.getPackageName(this.selectedKey);
      if (this.selectedClass) {
        result +=
          " " +
          stringHelpers.capitalise(this.selectedClass).replace(/class/gi, "") +
          " Class";
      }

      return result;
    },
    packageIntro: function () {
      var result = packageHelpers.getPackageIntro(this.selectedKey);

      return result;
    }
  },
  meta: {
    title: function () {
      var result = "How Many Stamps For A";
      if (stringHelpers.isVowel(this.packageName.substring(0, 1))) {
        result += "n";
      }
      result += " " + this.packageName + "?";
      return result;
    },
    description: function () {
      var result =
        "Find out how many stamps you'll need to post your " +
        this.packageName.toLowerCase() +
        " through Royal Mail within the UK.";
      return result;
    },
  },
};
</script>

<style lang="scss">
</style>