<template>
  <div class="row">
    <div class="col">
      <slot></slot>
      <Advert adType="pageIntro"></Advert>
    </div>
  </div>
</template>

<script>
import Advert from "@/components/Advert";

export default {
  name: "PageIntro",
  components: {
    Advert
  }
};
</script>