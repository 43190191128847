<template>
  <div class="container footer w-100 mt-5">
    <Advert adType="footer"></Advert>
    <component :is="footerBody"></component>
  </div>
</template>

<script>
import Advert from "@/components/Advert";
import siteHelpers from '@/scripts/helpers/siteHelpers.js';
import { defineAsyncComponent } from "vue";

export default {
  name: "Footer",
  components: { Advert },
  computed: {
    footerBody() {
      var siteName = siteHelpers.getSiteName();
      return defineAsyncComponent(() => import(`@/sites/${siteName}/components/FooterBody.vue`))
    }
  }
};
</script>

<style lang="scss">
@media(min-width:576px) {
  .footer {
    font-size: 75%;
  }
}
</style>