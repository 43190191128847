<template>
  <li><a :href="item.url">{{ item.title }}</a></li>
</template> 

<script>

export default {
  name: "LinkListItem",
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      }
    }
  }
};
</script>

<style lang="scss">

</style>