<template>
  <div class="col">
    <PageIntro>
      <p>
        If you're sending a large volume of
        <a href="/">letters and parcels</a>, you can purchase rolls of postage stamps to keep them handy for when you need them.
      </p>
    </PageIntro>

    <h2>First Class stamp rolls</h2>
    <p>In the UK, a roll contains 10,000 first class stamps.</p>
    <p>
      For smaller quantities, stamps are sold either in
      <a href="/how-many-stamps-in-a-sheet">sheets</a> or in
      <a href="/how-many-stamps-in-a-book">books</a>.
    </p>
  </div>
</template>

<script>
import PageIntro from "@/components/PageIntro";

export default {
  name: "HowManyStampsInARollPage",
  components: { PageIntro },
  meta: {
    title: "How Many Stamps Are In A Roll?",
    description:
      "How many stamps are in a roll and how many stamps you will need for your letter or package"
  }
};
</script>