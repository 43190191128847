export default {
    capitalise: function (text) {
        return text.charAt(0).toUpperCase() + text.substring(1);
    },

    isVowel: function (x) {
        var result;
        x = x.toLowerCase();

        result = x == "a" || x == "e" || x == "i" || x == "o" || x == "u";
        return result;
    },

    sortByProperty: function (list, propertyName) {
        list.sort(function (a, b) {
            let x = a[propertyName].toLowerCase();
            let y = b[propertyName].toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });

    }
};