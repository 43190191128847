<template>
  <span>
    <Package
      v-for="item in filteredItems"
      :item="item"
      :key="item.name"
      :selected="selected"
      :selectedClass="selectedClass"
    ></Package>
  </span>
</template> 

<script>
import Package from "./Package";

export default {
  name: "PackageList",
  components: { Package },
  props: {
    selected: String,
    selectedClass: String,
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    onlyPrimary: { type: Boolean, default: true }
  },
  computed: {
    filteredItems: function() {
      var result;

      if (!this.onlyPrimary) {
        result = this.items;
      } else {
        result = this.items.filter(c => c.primary || c.key === this.selected);
      }

      return result.sort(this.comparePackages);
    }
  },
  methods: {
    comparePackages: function(a, b) {
      if (a.key === this.selected) return -1;
      if (b.key === this.selected) return 1;
      return 0;
    }
  }
};
</script>

<style lang="scss"></style>