import Vue from 'vue';
import VueRouter from 'vue-router';
import ArticlePage from '@/pages/ArticlePage.vue';
import siteHelpers from '@/scripts/helpers/siteHelpers.js';

const routes = [
  { name: 'Article', path: '/articles/:shortname', component: ArticlePage },
  { name: 'Articles', path: '/articles', component: ArticlePage }
];

// Add specific routes for the current site
const siteRoutes = siteHelpers.loadScript('routes.js');
siteRoutes.forEach((routeList) => {
  routeList.forEach((route) => {
    routes.push(route);
  });
});

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  routes,
});