<template>
  <div class="container package mb-3">
    <div class="row">
      <div class="col">
        <a :href="url">{{ item.name }}</a>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <PackageSize
          :length="packageData.length"
          :width="packageData.width"
          :depth="packageData.depth"
          :note="packageData.sizeNote"
        ></PackageSize>
      </div>
    </div>
    <div class="row" v-if="selected==item.key">
      <div class="col pl-5">
        <PackageTable
          :weights="packageData.weights"
          :selectedClass="selectedClass"
          :selectedKey="selected"
        ></PackageTable>
      </div>
    </div>
  </div>
</template> 

<script>
import PackageTable from "./PackageTable";
import PackageSize from "./PackageSize";
import packageHelpers from "../scripts/packageHelpers.js";

export default {
  name: "Package",
  components: { PackageTable, PackageSize },
  data: function() {
    return { itemData: null };
  },
  props: {
    selected: String,
    selectedClass: String,
    item: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  computed: {
    url: function() {
      return this.item
        ? "/" +
            this.item.name.toLowerCase().replace(" ", "") +
            (this.selectedClass ? "/" + this.selectedClass : "")
        : null;
    },
    packageData: function() {
      return packageHelpers.getPackageData(this.item.key);
    }
  }
};
</script>

<style lang="scss">
.package-size {
  font-size: 75%;
}
</style>