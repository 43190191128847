<template>
  <div class="container header w-100">
    <h1>{{ title }}</h1>
    <div class="logo"><a href="/" :title="title"><img :alt="title" :src="logo" /></a></div>
  </div>
</template>

<script>
import siteHelpers from '@/scripts/helpers/siteHelpers.js'

export default {
  name: "Header",  
  props:['title'],
  computed: {
    logo: function () {
      return `/assets/${siteHelpers.getSiteName()}/logo.png`;
    }
  }
};
</script>

<style lang="scss">

</style>