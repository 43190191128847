<template>
  <b-table striped :items="filteredWeights" class="package-table">
    <template v-slot:head(first_class)>
      <a class="package-head" :href="headLink('firstclass')">First Class</a>
    </template>
    <template v-slot:head(second_class)>
      <a class="package-head" :href="headLink('secondclass')">Second Class</a>
    </template>
    <template v-slot:cell(first_class)="data">
      <span v-if="data.value.stamps?true:false">{{stamps(data.value.stamps)}}</span>
      <span v-if="data.value.ref" v-html="refLink(data.value.ref)"></span>
      <span v-if="data.value.note" v-html="data.value.note"></span>
    </template>
    <template v-slot:cell(second_class)="data">
      <span v-if="data.value.stamps?true:false">{{stamps(data.value.stamps)}}</span>
      <span v-if="data.value.ref" v-html="refLink(data.value.ref)"></span>
      <span v-if="data.value.note" v-html="data.value.note"></span>
    </template>
  </b-table>
</template> 

<script>
import packageHelpers from "../scripts/packageHelpers.js";

export default {
  name: "Package",
  props: {
    weights: {
      type: Array,
      default: function() {
        return [];
      }
    },
    selectedKey: null,
    selectedClass: null
  },
  computed: {
    filteredWeights: function() {
      var result = this.weights,
        i;

      if (this.selectedClass) {
        for (i in result) {
          if (this.selectedClass === "firstclass") {
            delete result[i].second_class;
          } else if (this.selectedClass === "secondclass") {
            delete result[i].first_class;
          }
        }
      }
      return result;
    }
  },
  methods: {
    stamps: function(stamps) {
      return stamps + " stamp" + (stamps > 1 ? "s" : "");
    },
    refLink: function(key) {
      return (
        'See <a href="/' +
        key +
        '">' +
        packageHelpers.getPackageName(key) +
        "</a>"
      );
    },
    headLink: function(key) {
      return "/" + this.selectedKey + "/" + key;
    }
  }
};
</script>

<style lang="scss">
.package-table {
  font-size: 90%;
  .package-head {
    color: black;
    text-decoration: none;
  }
}
.package-size {
  font-size: 75%;
}
</style>