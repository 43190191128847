<template>
  <div class="container mt-4">
    <PageIntro>
      <p>You've got questions about <em>how you recycle</em> all of the things you need to recycle? How to recycle
        unusual items without sending them to landfill?
        <a href="/">How Do You Recycle</a> has the recycling answers!
      </p>
      <p>Select the item below and we'll tell you how you recycle it...</p>
    </PageIntro>
    <MaterialsList></MaterialsList>
  </div>
</template>

<script>
import PageIntro from "@/components/PageIntro";
import MaterialsList from "../components/MaterialsList.vue";

export default {
  name: "HomePage",
  components: { PageIntro, MaterialsList }
};
</script>

<style lang="scss">

</style>