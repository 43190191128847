import Vue from 'vue';
import router from './scripts/router.js'
import App from './App.vue';
// import '@/styles/custom.scss';
import BootstrapVue from 'bootstrap-vue';
import VueAnalytics from 'vue-analytics';
import siteHelpers from '@/scripts/helpers/siteHelpers.js';

siteHelpers.importCSS();

Vue.config.productionTip = false

// Bootstrap
Vue.use(BootstrapVue);

// Google Analytics
if (!siteHelpers.isDev() && siteHelpers.getAnalyticsId()) {
  Vue.use(VueAnalytics, {
    id: siteHelpers.getAnalyticsId(),
    router
  });
}

new Vue({
  template: '<App/>',
  components: { App },
  router,
  beforeMount: function () {
    Vue.prototype.$app = this;
  },
  render: h => h(App),
}).$mount('#app');
