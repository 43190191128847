<template>
  <ul>
    <LinkListItem
      v-for="item in items"
      :item="item"
      :key="item.key"
    ></LinkListItem>
  </ul>
</template> 

<script>
import LinkListItem from "@/components/LinkListItem.vue";

export default {
  name: "LinkList",
  components: { LinkListItem },
  props: {
    items: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
};
</script>

<style lang="scss"></style>