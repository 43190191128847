<template>
  <div class="container mt-4">
    <PageIntro>
      <p>
        Want to know how many stamps you need to post your letter or parcel through Royal Mail within the UK?
        <a href="/">How Many Stamps</a> is the right place!
      </p>
      <p>Select the type of package below and we'll tell you how many stamps you'll need...</p>
    </PageIntro>
    <PackageList :items="items"></PackageList>

    <div class="container">
      <div class="row mb-3">
        <div class="col">
          <a href="/tips-for-saving-postage">Tips For Saving On Postage Costs</a>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <a href="/how-many-stamps-in-a-book">How Many Stamps In A Book?</a>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <a href="/how-many-stamps-in-a-sheet">How Many Stamps In A Sheet?</a>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <a href="/how-many-stamps-in-a-roll">How Many Stamps In A Roll?</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PackageList from "../components/PackageList";
import PageIntro from "@/components/PageIntro";
import packages from "../data/packages.json";

export default {
  name: "HomePage",
  components: { PackageList, PageIntro },
  data: function () {
    return {
      items: []
    };
  },
  created: function () {
    this.items = packages;
  }
};
</script>

<style lang="scss">

</style>