import HomePage from './pages/HomePage.vue';
import PackagePage from './pages/PackagePage.vue';
import ParcelPage from './pages/ParcelPage.vue';
import TipsForSavingPostagePage from './pages/TipsForSavingPostagePage.vue';
import HowManyStampsInARollPage from './pages/HowManyStampsInARollPage.vue';
import HowManyStampsInABookPage from './pages/HowManyStampsInABookPage.vue';
import HowManyStampsInASheetPage from './pages/HowManyStampsInASheetPage.vue';

export default [
    { name: 'TipsForSavingPostagePage', path: '/tips-for-saving-postage', component: TipsForSavingPostagePage },
    { name: 'HowManyStampsInARoll', path: '/how-many-stamps-in-a-roll', component: HowManyStampsInARollPage },
    { name: 'HowManyStampsInABook', path: '/how-many-stamps-in-a-book', component: HowManyStampsInABookPage },
    { name: 'HowManyStampsInASheet', path: '/how-many-stamps-in-a-sheet', component: HowManyStampsInASheetPage },
    { name: "Parcel", path: '/parcel/:class?', component: ParcelPage },
    { name: 'PackageClass', path: '/:package/:class?', component: PackagePage },
    { path: '/', component: HomePage }
];