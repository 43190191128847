<template>
  <div class="advert mt-3 mb-3" v-if="shouldRender">
    <ins class="adsbygoogle" :data-ad-client="client" :data-ad-slot="slot" :data-ad-format="adFormat" :style="adStyle"
      data-full-width-responsive="true"></ins>
  </div>
</template>

<script>
import siteHelpers from '@/scripts/helpers/siteHelpers.js';
import env from '@/env.js';

export default {
  name: "Advert",
  props: {
    adFormat: {
      type: String,
      required: false,
      default: "auto"
    },
    adStyle: {
      type: String,
      required: false,
      default: "display: block"
    },
    adType: {
      type: String,
      required: true
    }
  },
  computed: {
    shouldRender: function () {
      return !siteHelpers.isDev() && this.client;
    },
    client: function () {
      return env.ads.adClient;
    },
    slot: function () {
      return env.ads.slots[this.adType];
    }
  },
  mounted() {
    if (!siteHelpers.isDev()) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});

    }
  }
};
</script>