<template>
  <div class="container">
    <PageIntro>
      <p>
        Want to know how many stamps you need to post a {{packageName}} through Royal Mail within the UK?
        This
        <a
          href="/"
        >How Many Stamps</a> page has the answer!
      </p>
      <p>First select the size of parcel or package you're looking to send...</p>
    </PageIntro>
    <PackageList :items="items" :selectedClass="selectedClass" :onlyPrimary="false"></PackageList>
  </div>
</template>

<script>
import PackageList from "../components/PackageList";
import packages from "../data/packages.json";
import stringHelpers from "@/scripts/helpers/stringHelpers.js";
import PageIntro from "@/components/PageIntro";

export default {
  name: "ParcelPage",
  components: { PackageList, PageIntro },
  data: function() {
    return {
      items: [],
      selectedClass: null
    };
  },
  created: function() {
    this.items = this.getParcelPackages();
    this.selectedClass = this.$route.params.class;
  },
  methods: {
    getParcelPackages: function() {
      var result = [],
        i;

      for (i in packages) {
        if (
          packages[i].key === "smallparcel" ||
          packages[i].key === "mediumparcel" ||
          packages[i].key === "largeparcel"
        ) {
          result.push(packages[i]);
        }
      }

      return result;
    }
  },
  computed: {
    packageName: function() {
      var result = "Parcel or Package";
      if (this.selectedClass) {
        result +=
          " " +
          stringHelpers.capitalise(this.selectedClass).replace(/class/gi, "") +
          " Class";
      }

      return result;
    }
  },
  meta: {
    title: function() {
      return "How Many Stamps For A " + this.packageName + "?";
    }
  }
};
</script>

<style lang="scss">
</style>