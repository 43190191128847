<template>
  <p class="package-size">
    Max. {{ length }}mm (L)
    <span v-if="width">x {{width}}mm (W)</span>
    <span v-if="depth">x {{depth}}mm (D)</span>
    <span v-if="note">{{note}}</span>
  </p>
</template> 

<script>
export default {
  name: "PackageSize",
  data: function() {
    return { itemData: null };
  },
  props: {
    length: Number,
    width: Number,
    depth: Number,
    note: String,
    item: {
      type: Object,
      default: function() {
        return {};
      }
    }
  }
};
</script>

<style lang="scss">
.package-size {
  font-size: 75%;
}
</style>