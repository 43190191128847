<template>
  <div class="container" v-html="body"></div>
</template>

<script>
import siteHelpers from '@/scripts/helpers/siteHelpers.js'
//import articlesData from "../sites/howmanystamps/data/articles.json";

const articlesData = siteHelpers.loadJson('articles.json');

export default {
  name: "ArticlePage",
  data: function () {
    return {
      articleShortname: null,
      articleBody: null,
      articleTitle: null,
      articleDescription: null,
      items: []
    };
  },
  created: function () {

    if (articlesData) {
      this.items = articlesData.articles;

      this.articleShortname = this.$route.params.shortname;

      if (!this.articleShortname) {
        this.articleBody = this.defaultBody;
      }
      else {
        for (var article of this.items) {
          if (article.shortname === this.articleShortname) {
            this.articleBody = article.body;
            this.articleDescription = article.description;
            this.articleTitle = article.title;
            break;
          }
        }
      }
    }
    else {
      this.articleBody = 'No articles found.'
    }
  },
  computed: {
    body: function () {
      return this.articleBody;
    },
    defaultBody: function () {
      var result = `<ul>`;
      for (var article of this.items) {
        result += `<li><a href="/articles/${article.shortname}">${article.title}</a></li>`;
      }
      result += `</ul>`;

      return result;
    }
  },
  meta: {
    title: function () {
      return this.articleTitle || articlesData?.title;
    },
    description: function () {
      return this.articleDescription || articlesData?.description;
    },
  }
};
</script>

<style lang="scss">

</style>