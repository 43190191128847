<template>
  <div class="container" v-html="body"></div>
</template>

<script>
import siteHelpers from '@/scripts/helpers/siteHelpers.js'

const materialsData = siteHelpers.loadJson('materials.json');

export default {
  name: "MaterialPage",
  data: function () {
    return {
      materialShortname: null,
      materialBody: null,
      materialName: null,
      materialDescription: null,
      materials: []
    };
  },
  created: function () {
    if (materialsData) {
      this.materials = materialsData;

      this.materialShortname = this.$route.params.material;

      if (!this.materialShortname) {
        this.materialBody = this.defaultBody;
      }
      else {
        for (var material of this.materials) {
          if (material.shortname === this.materialShortname) {
            this.materialBody = material.body;
            this.materialDescription = material.description;
            this.materialName = material.name;
            break;
          }
        }
      }
    }
    else {
      this.materialBody = 'No materials found.'
    }
  },
  computed: {
    body: function () {
      return this.materialBody;
    },
    defaultBody: function () {
      var result = `<ul>`;
      for (var material of this.materials) {
        result += `<li><a href="/materials/${material.shortname}">${material.name}</a></li>`;
      }
      result += `</ul>`;

      return result;
    }
  },
  meta: {
    title: function () {
      return `How Do You Recycle ${this.materialName}?`;
    },
    description: function () {
      return this.materialDescription;
    },
  }
};
</script>

<style lang="scss">

</style>