import packages from '../data/packages.json';
import packageData from '../data/packageData.json';

export default {
    getPackageName: function (key) {
        var result,
            i;

        for (i in packages) {
            if (packages[i].key === key) {
                result = packages[i].name;
                break;
            }
        }

        return result;
    },
    getPackageIntro: function (key) {
        var result,
            i;

        for (i in packages) {
            if (packages[i].key === key) {
                result = packages[i].intro;
                break;
            }
        }

        return result;
    },
    getPackageData: function (key) {
        var i,
        result,
        dataKey;

        for (i in packages) {
            if (packages[i].key === key) {
                dataKey = packages[i].data;
                break;
            }
        }

        for (i in packageData) {
            if (packageData[i].key === dataKey) {
                result = packageData[i];
                break;
            }
        }

        return result;
    }
};