import siteHelpers from '@/scripts/helpers/siteHelpers.js';

export default {
    values: null,

    setupPage: function (app) {
        this.setMetaTags(app);
        this.addGoogleTagManager();
    },
    setMetaTags: function (app) {
        var title,
            pageValues;

        this.values = siteHelpers.getEnvValue('meta');

        pageValues = this.getMetaData(app);
        if (pageValues) {
            for (var pageValue in pageValues) {
                this.values[pageValue] = pageValues[pageValue];
            }
        }

        if (this.values.title && this.values.titleSuffix) {
            title = this.values.title;
            if (title !== this.values.titleSuffix) {
                title += ` | ${this.values.titleSuffix}`;
            }
            document.title = `${title}`;
        }

        this.removeStaleTags();

        // Loop through meta and add tags
        for (var i in this.values) {
            if (i !== 'title' && i !== 'titleSuffix' && i !== 'titleDelimiter') {
                this.addMetaTag(i, this.values[i]);
            }
        }

        return this.values;
    },
    // Add a meta tag to the head
    addMetaTag: function (key, value) {
        this.addTagToHead('meta', [{ name: 'name', value: key },
        { name: 'content', value: value },
        { name: 'dynamic', value: '' }
        ]);
    },
    addTagToHead: function (tagName, attributes) {
        var tag = document.createElement(tagName);
        for (var attr of attributes) {
            tag.setAttribute(attr.name, attr.value || '');
        }
        document.head.appendChild(tag);
    },
    addGoogleTagManager: function () {
        var tagManagerId = siteHelpers.getEnvValue('googleTagManagerId');
        if (tagManagerId) {
            this.addTagToHead('script', [{ name: 'async' }, { name: 'src', value: `https://www.googletagmanager.com/gtag/js?id=${tagManagerId}` }]);
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () { window.dataLayer.push(arguments); }
            window.gtag('js', new Date());
            window.gtag('config', tagManagerId);
        }
    },
    removeStaleTags() {
        Array.from(document.querySelectorAll('[dynamic]')).map(el => el.parentNode.removeChild(el));

    },
    getMetaData: function (component, result) {
        var meta = component.$options['meta'],
            i,
            val;

        // If we have meta info in this component
        if ((typeof meta === "object" || typeof meta === 'function') && (meta !== null)) {
            // Children override parent properties
            for (i in meta) {
                result = result || {};
                val = typeof meta[i] === 'function' ? meta[i].call(component) : meta[i];
                result[i] = val || result[i];
            }
        }

        // collect & aggregate child options if deep = true
        if (component.$children.length) {
            component.$children.forEach((childComponent) => {
                result = this.getMetaData(childComponent, result)
            })
        }

        return result
    },
    getMetaValues: function () {
        return this.values;
    }
};