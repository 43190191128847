<template>
  <LinkList :items="items"></LinkList>
</template> 

<script>
import LinkList from "@/components/LinkList";
import materials from "../data/materials.json";
import stringHelpers from "@/scripts/helpers/stringHelpers.js";

export default {
  name: "MaterialsList",
  components: { LinkList },
  data: function () {
    return {
      items: []
    };
  },
  created: function () {
    for (var material of materials) {
      this.items.push({ key: material.shortname, title: material.name, url: `/materials/${material.shortname}` })
    }
    
    stringHelpers.sortByProperty(this.items, 'title');
  }
};
</script>

<style lang="scss">

</style>