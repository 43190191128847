<template>
  <div class="col">
    <PageIntro>
      <p>
        If you're
        <a href="/">sending letters and parcels</a> through the post you can purchase sheets of postage stamps to keep them handy for when you need them.
      </p>
    </PageIntro>

    <h2>First Class stamp sheets</h2>
    <p>1st class stamps are available in the following sheets:</p>
    <ul>
      <li>
        <strong>50</strong> x 1st class stamps
      </li>
      <li>
        <strong>100</strong> x 1st class stamps
      </li>
    </ul>
    <p>How many first class stamps will you need? Select a package type to find out:</p>
    <ul>
      <li v-for="item in items" :key="item.key">
        <a :href="'/' + item.key + '/firstclass'">{{item.name}}</a>
      </li>
    </ul>

    <h2>Second Class stamp sheets</h2>
    <p>1st class stamps are available in the following sheets:</p>
    <ul>
      <li>
        <strong>50</strong> x 2nd class stamps
      </li>
      <li>
        <strong>100</strong> x 2nd class stamps
      </li>
    </ul>
    <p>How many second class stamps will you need? Select a package type to find out:</p>
    <ul>
      <li v-for="item in items" :key="item.key">
        <a :href="'/' + item.key + '/secondclass'">{{item.name}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import PageIntro from "@/components/PageIntro";
import packages from "../data/packages.json";

export default {
  name: "HowManyStampsInASheetPage",
  components: { PageIntro },
  data: function() {
    return { items: null };
  },
  created: function() {
    this.items = packages;
  },
  meta: {
    title: "How Many Stamps Are In A Sheet?",
    description:
      "How many stamps are in a sheet and how many stamps you will need for your letter or package"
  }
};
</script>